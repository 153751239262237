import React from "react"
import ReactMarkdown from "react-markdown"
import { Link, graphql } from "gatsby"
import Lightbox from "react-images"
import queryString from "query-string"
import Slider from "react-slick"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"

import {
  AuthorInfoField,
  CinemaTile,
  DataBox,
  Image,
  ImageGrid,
  LastUpdateField,
  Layout,
  StyledLink
} from "../../components"

import { dateString } from "../../utils/helper"

import styles from "./index.module.css"

const ImageCaption = (image) => {
  const imageLicence = () => (
    <StyledLink
      href={image.image.license.url}
      label={image.image.license.name}
      external={true}
    />
  )

  return (
    <>
      {`${image.image.title || "No title"} (${
        image.image.photographer || "No photographer"
      } | ${image.image.dateYear || "No date"} | `}

      {image.image.license && "License: "}
      {image.image.license && imageLicence()}
      {!image.image.license && "No license specified"}

      {!!image.image.courtesyOf && ` | Courtesy of: ${image.image.courtesyOf}`}

      {")"}
    </>
  )
}

class CinemaPage extends React.Component {
  constructor(props) {
    super(props)

    const data = props.data
    this.cinema = data.contentfulCinema
    this.author = this.cinema.author

    this.cinemas = data.allContentfulCinema.edges
    const index = this.cinemas.findIndex(
      (cinema) => cinema.node.url === this.cinema.url
    )
    const previousIndex =
      (index - 1 + this.cinemas.length) % this.cinemas.length
    const nextIndex = (index + 1) % this.cinemas.length

    this.previousCinema = this.cinemas[previousIndex]
    this.nextCinema = this.cinemas[nextIndex]

    this.images = this.cinema.images || [{}]
    this.heroImageWrapper = this.images ? this.images[0] : {}
    this.lightBoxImages = this.images
      .filter((i) => i.image && i.image.file.url)
      .map((image) => ({
        src: image.image.file.url + "?w=2000",
        srcSet: [
          `${image.image.file.url}?w=1280 1280w`,
          `${image.image.file.url}?w=1024 1024w`,
          `${image.image.file.url}?w=800 800w`,
          `${image.image.file.url}?w=500 500w`,
          `${image.image.file.url}?w=320 320w`
        ],
        caption: <ImageCaption image={image} />,
        alt: image.description
      }))

    // handle if original URL points to an image - should refactor?
    let lightboxOpen = false
    let currentImage = 0

    const queryParams = queryString.parse(props.location.search)

    console.log(queryParams)

    if (queryParams.image) {
      const imageId = queryParams.image

      lightboxOpen = true
      currentImage = this.images.map((i) => i.contentful_id).indexOf(imageId)
    }

    this.state = {
      lightboxOpen: lightboxOpen,
      currentImage: currentImage
    }

    this.sliderSettings = {
      dots: false,
      arrows: true,
      // centerMode: true,
      speed: 500,
      slidesToShow: 7,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 7
          }
        },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 5
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3
          }
        }
      ]
    }

    this.openLightbox = this.openLightbox.bind(this)
    this.openLightboxWithIndex = this.openLightboxWithIndex.bind(this)
    this.gotoPrevLightboxImage = this.gotoPrevLightboxImage.bind(this)
    this.gotoNextLightboxImage = this.gotoNextLightboxImage.bind(this)
    this.closeLightbox = this.closeLightbox.bind(this)
    this.handleThumbnailClick = this.handleThumbnailClick.bind(this)
    this.handleImageClick = this.handleImageClick.bind(this)
    this.browserNavigationHandler = this.browserNavigationHandler.bind(this)
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      window.addEventListener("popstate", this.browserNavigationHandler, false)
    }
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.browserNavigationHandler, false)
  }

  browserNavigationHandler(event) {
    const queryParams = queryString.parse(event.target.location.search)

    if (queryParams.image) {
      const imageId = queryParams.image
      const currentImage = this.images
        .map((i) => i.contentful_id)
        .indexOf(imageId)

      this.setState({ lightboxOpen: true, currentImage: currentImage })
    } else {
      this.setState({ lightboxOpen: false })
    }
  }

  updateImageBrowserUrl(imageIndex) {
    const image = this.images[imageIndex]
    const stateObj = `${image.contentful_id}`
    const pageTitle = `${this.cinema.title}`
    const url = `/cinema/${this.cinema.url}?image=${image.contentful_id}`

    if (typeof window !== `undefined`) {
      window && window.history.pushState(stateObj, pageTitle, url)
    }
  }

  openLightbox(event) {
    event.preventDefault()
    this.setState({ lightboxOpen: true, currentImage: 0 })
    this.updateImageBrowserUrl(0)
  }

  openLightboxWithIndex(index, event) {
    event.preventDefault()
    this.setState({ lightboxOpen: true, currentImage: index + 1 })
    this.updateImageBrowserUrl(index + 1)
  }

  closeLightbox(event) {
    event && event.preventDefault()
    this.setState({ lightboxOpen: false })

    const stateObj = `${this.cinema.title}`
    const pageTitle = `${this.cinema.title}`
    const url = `/cinema/${this.cinema.url}`

    if (typeof window !== `undefined`) {
      window.history.pushState(stateObj, pageTitle, url)
    }
  }

  gotoPrevLightboxImage() {
    const { currentImage } = this.state
    this.setState({ currentImage: currentImage - 1 })
    this.updateImageBrowserUrl(currentImage - 1)
  }

  gotoNextLightboxImage() {
    const { currentImage } = this.state
    this.setState({ currentImage: currentImage + 1 })
    this.updateImageBrowserUrl(currentImage + 1)
  }

  handleThumbnailClick(index) {
    this.setState({ currentImage: index })
    this.updateImageBrowserUrl(index)
  }

  handleImageClick() {
    const { currentImage } = this.state

    if (currentImage === this.images.length - 1) {
      return
    }

    this.setState({ currentImage: currentImage + 1 })
    this.updateImageBrowserUrl(currentImage + 1)
  }

  render() {
    return (
      <Layout>
        <Lightbox
          images={this.lightBoxImages}
          currentImage={this.state.currentImage}
          isOpen={this.state.lightboxOpen}
          onClickPrev={this.gotoPrevLightboxImage}
          onClickNext={this.gotoNextLightboxImage}
          onClickThumbnail={this.handleThumbnailClick}
          onClickImage={this.handleImageClick}
          onClose={this.closeLightbox}
          showThumbnails={false}
          preloadNextImage={true}
          backdropClosesModal={true}
          width={1280}
          theme={{ container: { background: "rgba(0, 0, 0, 1)" } }}
        />

        <div className={styles.topSection}>
          <div className={styles.title}>
            <h1 className={styles.cinemaTitle}>{this.cinema.title}</h1>

            {this.cinema.city && this.cinema.country && (
              <h2 className={styles.cityCountryHeader}>
                {[this.cinema.city, this.cinema.country].join(", ")}
              </h2>
            )}

            {this.cinema.alternativeNames && (
              <h2>
                {`Alternative names: ${this.cinema.alternativeNames.join(
                  ", "
                )}`}
              </h2>
            )}

            {this.cinema.status && (
              <h2>{this.cinema.status && `Status: ${this.cinema.status}`}</h2>
            )}
          </div>

          <div className={styles.info}>
            {this.author && <AuthorInfoField name={this.author.name} />}
            {this.cinema.updatedAt && (
              <LastUpdateField name={dateString(this.cinema.updatedAt)} />
            )}
          </div>
        </div>

        <div className={styles.mainContent}>
          <div className={styles.leftContainer}>
            <div className={styles.imagesContainer}>
              {this.images.length > 0 && (
                <div className={styles.heroImage}>
                  <Link
                    className={styles.link}
                    to={`/cinema/${this.cinema.url}?image=${this.heroImageWrapper.contentful_id}`}
                    onClick={this.openLightbox}
                  >
                    <Image
                      imageWrapper={this.heroImageWrapper}
                      queryParams={"w=800"}
                      style={styles.singleImage}
                    />
                  </Link>

                  {this.heroImageWrapper.title && (
                    <div className={styles.imageTitle}>
                      {this.heroImageWrapper.title}
                    </div>
                  )}
                </div>
              )}
              {this.images.length > 1 && (
                <ImageGrid
                  images={this.images.slice(1)}
                  cinema={this.cinema}
                  onClickHandler={this.openLightboxWithIndex}
                />
              )}
            </div>
          </div>

          <div className={styles.contentContainer}>
            <DataBox cinema={this.cinema} />

            {this.cinema.description && (
              <div className={styles.description}>
                <ReactMarkdown source={this.cinema.description.description} />
              </div>
            )}

            {this.cinema.linksAndReferences && (
              <div className={styles.description}>
                <h3>Links and References</h3>
                {/* replace links with a hrefs */}
                {this.cinema.linksAndReferences.linksAndReferences
                  .split("\n")
                  .filter((n) => n)
                  .map((line) => {
                    return line.match(/^https?/) ? (
                      <p key={line}>
                        <a
                          href={line}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {line}
                        </a>
                      </p>
                    ) : (
                      <p key={line}>{line}</p>
                    )
                  })}
              </div>
            )}

            {this.cinema.incomplete && (
              <div className={styles.incomplete}>
                <FontAwesomeIcon icon={faExclamationTriangle} />
                The description of this cinema is incomplete and may lack
                important information. In case you can contribute to this entry,
                don't hesitate to contact us by email (
                <a href="mailto:editorial@cinelandmarks.org">
                  editorial@cinelandmarks.org
                </a>
                ).
              </div>
            )}
          </div>
        </div>

        <div className={styles.navigation}>
          <div className={styles.navigationHeader}>
            You might also be interested in:
          </div>
          <Slider {...this.sliderSettings}>
            {this.cinemas.map((cin) => (
              <div id="holla">
                <div style={{ margin: 5 }}>
                  <CinemaTile cinema={cin.node} ellipsis={true} />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </Layout>
    )
  }
}

export default CinemaPage

export const query = graphql`
  query CinemaById($url: String!) {
    contentfulCinema(url: { eq: $url }) {
      title
      url
      author {
        name
      }
      description {
        description
      }
      linksAndReferences {
        linksAndReferences
      }
      alternativeNames
      address {
        lon
        lat
      }
      street
      city
      state
      zipCode
      country
      homepage
      email
      numOfSeats
      numOfScreens
      yearOfReopening
      yearOfFirstOpening
      yearOfConstruction
      featureList {
        description
      }
      status
      architecturalStyle {
        name
      }
      createdAt
      updatedAt
      incomplete
      images {
        contentful_id
        courtesyOf
        title
        dateYear
        photographer
        description
        license {
          name
          url
        }
        image {
          id
          file {
            url
          }
        }
      }
    }
    allContentfulCinema(sort: { fields: createdAt, order: ASC }) {
      edges {
        node {
          id
          url
          title
          city
          country
          yearOfReopening
          yearOfFirstOpening
          yearOfConstruction
          images {
            title
            image {
              fluid(maxHeight: 500, maxWidth: 500) {
                ...GatsbyContentfulFluid
              }
            }
          }
          updatedAt
        }
      }
    }
  }
`
