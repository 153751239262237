export const dateString = (input) => {
  const currentDate = new Date(input);

  const date = currentDate.getDate();
  const month = currentDate.getMonth(); //Be careful! January is 0 not 1
  const year = currentDate.getFullYear();

  const output = date + "/" + (month + 1) + "/" + year;
  return output;
};
